var window_width;
var menu_counter = 0;
var scroll_top = 0;
$('#menu_btn').click(function(){
	if(menu_counter == 0){
		scroll_top = document.documentElement.scrollTop || document.body.scrollTop;
		menu_counter++;
	}else{
		menu_counter = 0;
	}
	console.log(scroll_top);
	$("#menu_btn .nav-icon").toggleClass('nav_open');
	$("#app").toggleClass("menu_on");
	$("body").toggleClass("menu_on");
	$("#mob_logo").toggleClass("menu_on");
	$("#mobile_menu").toggleClass("on");
	$('#page').scrollTop(scroll_top);
	document.documentElement.scrollTop = document.body.scrollTop = scroll_top;
});

$("#app.menu_on #page").click(function(){
	$("#menu_btn .nav-icon").toggleClass('nav_open');
	$("#app").toggleClass("menu_on");
	$("body").toggleClass("menu_on");
	$("#mob_logo").toggleClass("menu_on");
});
function heightMatch(){
	$(".heightMatch").each(function(){
		var theHeight = $(this).find(".firstHeight").height();
		$(this).find(".firstHeight").height(theHeight + "px");
		$(this).find(".secondHeight").height(theHeight + "px");
	});
}
function square(){
	$(".square").each(function(){
		var theWidth = $(this).width();
		$(this).height(theWidth + "px");
	});
}

function fullHeight(){
	
	windowWidth = $(window).width();
	windowHeight = $(window).height();
	
	$(".fullHeight").each(function(){
		
		$(this).height(windowHeight + "px");
		
	});

	$(".twoThirdsHeight").each(function(){
		
		
		if(windowHeight <= 768){
			$(this).height(windowHeight);
		}else{
			$(this).height("768px");
		}
	});
	
	$(".subFullHeight").each(function(){
		
		if(windowWidth <= 768){
			$(this).height((windowHeight - 110) + "px");
		}else{
			$(this).height((windowHeight - 150) + "px");
		}
		
	});

	$(".square").each(function(){
		
		var sq_width = $(this).width();
		$(this).height(sq_width + "px");
		
	});
}

function twoThirdsHeight(){
	
	windowWidth = $(window).width();
	windowHeight = $(window).height();
	windowHeight = windowHeight/0.66666;

	$(".twoThirdsHeight").each(function(){
		
		$(this).height(windowHeight + "px");
		
	});

}

function fadeEffects(){

	$('.scrollFade').each(function(index, element){
		
		var effect = $(this).attr("data-fade");
		var reverse = $(this).attr("data-reverse");

	    var inview = new Waypoint({
	        element: element,
	        handler: function (direction) {
		        if(direction === "down"){
		        	if($(element).hasClass("reverse")){
		        		$(element).removeClass(reverse);
		        	}
			        if($(element).hasClass("delay")){
				        
				        setTimeout(function(){
					        $(element).addClass("animated " + effect);
							$(element).css('opacity','1');
				        }, 800);
				        
			        }else if($(element).hasClass("delay_half")){
				        
				        setTimeout(function(){
					        $(element).addClass("animated " + effect);
							$(element).css('opacity','1');
				        }, 400);
				        
			        }else if($(element).hasClass("delay_quarter")){
				        
				        setTimeout(function(){
					        $(element).addClass("animated " + effect);
							$(element).css('opacity','1');
				        }, 200);
				        
			        }else{
				        $(element).addClass("animated " + effect);
						$(element).css('opacity','1');
			        }
			        if($(element).hasClass("colours")){
			        	$(".colours .square").each(function(i, e){
			        		var count = i + 1;
			        		$(this).addClass("fadeIn" + count);
			        	});
			        }
				}else{
					if($(element).hasClass("reverse")){
			        	$(element).removeClass(effect);
				        $(element).addClass(reverse);
			        }
				}
			},
			offset: '95%'
		});
	
	});

	$('.scrollDraw').each(function(index, element){
		
		var effect = $(this).attr("data-line");
		
	    var inview = new Waypoint({
	        element: element,
	        handler: function (direction) {
		        if(direction === "down"){
			        if($(element).hasClass("delay_1")){
				        
				        setTimeout(function(){
					        $(element).addClass(effect);
				        }, 200);
				        
			        }else if($(element).hasClass("delay_2")){
				        
				        setTimeout(function(){
					        $(element).addClass(effect);
				        }, 400);
				        
			        }else if($(element).hasClass("delay_3")){
				        
				        setTimeout(function(){
					        $(element).addClass(effect);
				        }, 600);
				        
			        }else if($(element).hasClass("delay_4")){
				        
				        setTimeout(function(){
					        $(element).addClass(effect);
				        }, 800);
				        
			        }else{
				        $(element).addClass(effect);
			        }
					
				}
			},
			offset: '98%'
		});
	
	});
	
}

var testimonials_slider = $(".testimonials_slider .slider").bxSlider({
	auto: true,
	controls: false,
	autoControls: false,
	speed: 800,
	stopAutoOnClick: true,
	randomStart: false
});

$(".testimonials_slider .slide_prev").click(function(){
	
	testimonials_slider.goToPrevSlide();
	testimonials_slider.stopAuto();
	
});
	
$(".testimonials_slider .slide_next").click(function(){

	testimonials_slider.goToNextSlide();
	testimonials_slider.stopAuto();
	
});

$(".expander").each(function(index, element){
	var expander = $(this);
	var thecounter = 0;
	$(this).find(".expander_click").click(function(){
		if(thecounter == 0){
			$(this).find('.expander_arrow').html('<i class="fa fa-chevron-up"></i>');
			thecounter++;
		}else{
			$(this).find('.expander_arrow').html('<i class="fa fa-chevron-down"></i>');
			thecounter = 0;
		}
		$(expander).toggleClass("on");
		$(expander).find(".expander_hide").toggleClass("on");
	});
});

$(".cancel-btn").click(function(){
	$("#screen_blur").fadeOut();
	$("#notification").fadeOut();
	$("#notification").removeClass('on');
	$(".confirm_box").fadeOut();
	$(".confirm_box").removeClass('on');
});

var g_counter = 0;
$("#gallery.button").click(function(){
	$(".work_slider").toggleClass('off');
	$("#close_gallery").toggleClass('on');
	$(".gallery").toggleClass('button');
	$('html,body').animate({scrollTop: $("#recent_work").offset().top});
	
	if(g_counter == 0){
		setTimeout(function(){
			$("#work_container").css('position','absolute');
			$("#gallery").css('position','relative');
			g_counter++;
		},1000);
	}else{
		$("#gallery").css('position','absolute');
		$("#work_container").css('position','relative');
		g_counter = 0;
	}
});
$("#close_gallery").click(function(){
	$(".work_slider").toggleClass('off');
	$("#close_gallery").toggleClass('on');
	$(".gallery").toggleClass('button');
	$('html,body').animate({
	   scrollTop: $("#recent_work").offset().top
	});
	$("#gallery").css('position','absolute');
	$("#work_container").css('position','relative');
	g_counter = 0;
});
$(".scrollBtn").click(function(){
	$('html,body').animate({
	   scrollTop: $(".scrollTo").offset().top
	});
});
$(".btn-work").each(function(){
	$(this).click(function(){
		$(".btn-work").each(function(){
			$(this).removeClass("active");
		});
		$(this).addClass("active");
	});
});
$(".wwd_col").each(function(){
	var ele = $(this);
	$(this).find(".round_btn").click(function(){
		$(ele).toggleClass("on");
		$(ele).find(".fa-plus").toggleClass("hide");
		$(ele).find(".fa-minus").toggleClass("hide");
	});
});
$(window).resize(function() {
    window_width = $(window).width();
    square();
    fullHeight();
    heightMatch();
});
$(document).ready(function(){
	square();
	heightMatch();
	fullHeight();
	fadeEffects();
});